import * as utils from './_utils';
import ImageMap from "image-map";
// require('image-map/dist/image-map.jquery');
import $ from 'jquery';

const defaults = {
    $element: undefined,
    data: {}
};

export default class ImageMapContainer {
    constructor(properties = {}) {
        const members = $.extend(true, {}, defaults, properties);

        this.$element = members.$element;
        this.data = members.data;
        this.options = members.options;

    }

    init() {
        ImageMap('img[usemap]', 500);

        const $gallery = this.$element.find('.js-gallery');
        const $galleryLinks = $gallery.find('a');

        this.$element.find('area').each(function (index) {
            $(this).on('click', function (e) {
                e.preventDefault();
                const $trigger = $galleryLinks.filter('[data-js-map-image='+$(this).attr('title')+']');
                if ($trigger.length) {
                    $trigger[0].click();
                }
            })
            // $(this).hover(
            //     function () {
            //         var coords = $(this).attr('coords').split(','),
            //             width = $('.image-map-container').width(),
            //             height = $('.image-map-container').height();
            //         $('.image-map-container .map-selector').addClass('hover').css({
            //             'left': coords[0]+'px',
            //             'top': coords[1] + 'px',
            //             'right': width - coords[2],
            //             'bottom': height - coords[3]
            //         })
            //     },
            //     function () {
            //         $('.image-map-container .map-selector').removeClass('hover').attr('style','');
            //     }
            // );
        });
    }
}
