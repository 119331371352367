// jQuery
import $ from 'jquery';

window.jQuery = $;
window.$ = $;

// require('process/');
require('./_jquery-extended');
require('./_config');
require('./_utils').init();

