/**
 * Extended functionality for jQuery
 */
import $ from 'jquery';

$.fn.extend({
    /**
     * "only" is no longer available as of jQuery 3
     * @param selector
     * @returns {*}
     */
    only: function only(selector) {
        var delimiter = selector.indexOf(' ');

        if (~delimiter) {
            var context = selector.substr(0, delimiter);
            var subject = selector.substr(delimiter + 1);
            var $parent;
            var $nested;

            $parent = this.closest(context);
            $nested = $parent.find(context).find(subject);

            return this.find(subject).not($nested);
        }

        return this.find(selector);
    },
});
